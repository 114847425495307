import './App.css';
import StoreBadge from 'react-store-badge';
import screen_1 from './Images/Screen-1.jpg';
import screen_2 from './Images/Screen-2.jpg';
import screen_3 from './Images/Screen-3.jpg';
import screen_4 from './Images/Screen-4.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter
 } from '@fortawesome/free-brands-svg-icons';

function App() {
  return (
    <div className="App">
        <Header />      
        <AppStoreBadge />
        <Images />        
    </div>
  );
}

function Header() {
  return (
    <header className="App-header">
      <div className="Titles">
        <h1 className="Title">GRImageViewer</h1>
        <h3 className="SubTitle">Your ultimate tool app for Ricoh GR III camera</h3>
      </div>      
    </header>        
  )
}

function AppStoreBadge() {
  return (
    <div className="AppStoreBadge">
      <StoreBadge
        name="GRImageViewer"        
        appStoreUrl="https://apps.apple.com/app/apple-store/id1530691089?pt=117350401&ct=website&mt=8"
      />
    </div>
  );
}

function Images() {
  return (
    <div className="Images">
      <img className ="Image" width="300" height ="600" src={screen_1} />
      <img className ="Image" width="300" height ="600" src={screen_2} />
      <img className ="Image" width="300" height ="600" src={screen_3} />
      <img className ="Image" width="300" height ="600" src={screen_4} />
    </div>
  )
}

function TwitterLink() {
  return (
    <div className="TwitterLinkContainer">
      <h3 className="TwitterLinkTitle">@GRImageViewer</h3>
      <a href="https://twitter.com/grimageviewer" className="TwitterLink">
        <FontAwesomeIcon icon={faTwitter} size="2x" />
      </a>
    </div>
  )
}

export default App;