import React from 'react';
import ReactDOM from 'react-dom';
import { Route } from 'react-router';
import './index.css';
import App from './App';
const reload = () => window.location.reload();
<Route path="/app-ads.txt" onEnter={reload} />
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);